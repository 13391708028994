<template>
    <transition name="fade">
        <div class="container mt-4">
            <h5 class="text-center">Retrouvez facilement les rendez-vous d'un de vos patients</h5>

            <div class="row justify-content-center text-center mt-3">
                <span class="col-md-12">Saisissez l'une des informations suivantes : Nom Prénom / Matricule / Email (min. 3 caractères pour rechercher)</span>
                <v-select :options="userList" @search="onSearchUser" @input="onInputUser" :value="userList.find(u => u.id === user_id)" class="col-10 col-md-6 mt-2 px-0">
                    <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">Aucun résultat pour : <em>{{ search }}</em>.</template>
                        <em v-else style="opacity: 0.5">Taper 3 caractères pour rechercher</em>
                    </template>
                </v-select>
            </div>

            <div v-if="user_id">
                <div v-if="!loading" class="row mt-4">
                    <div class="col-md-12">
                        <timeline :items="appointments" :display-delete-button="false"></timeline>
                    </div>
                </div>
                <div v-else class="row mt-5">
                    <div class="col-md-12 text-center">
                        <div id="spinner" class="spinner-border"></div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import userApi from "@/api/user"
    import calendarApi from "@/api/calendar"
    import Timeline from "@/components/front/my-appointments/Timeline.vue"

    export default {
        name: "Index",
        components: { Timeline },
        data() {
            return {
                config: Vue.$config,
                loading: false,
                user_id: null,
                userList: [],
                appointments: null
            }
        },
        methods: {
            async onSearchUser(search) {
                if(search.length === 0) {
                    this.userList = []
                }

                if(search.length > 2) {
                    let res = await userApi.searchTerm(search)
                    this.userList = res.data.users
                }
            },
            onInputUser(u) {
                u ? this.user_id = u.id : this.user_id = null
                this.getAppointments()
            },
            async getAppointments() {
                this.loading = true
                let res = await calendarApi.getUserAppointments({
                    user_id: this.user_id,
                    app_id: this.config.app_id
                })

                this.appointments = res.data.appointments
                this.loading = false
            }
        },
        beforeDestroy() {
            this.userList = []
        }
    }
</script>

<style scoped>
    #spinner {
        width: 3rem;
        height: 3rem;
        color: #3F51B5;
    }

    @media(max-width: 992px) {
        #spinner {
            width: 2rem;
            height: 2rem;
        }
    }
</style>
